<template>
  <Breadcrumb></Breadcrumb>
  <PageHeader
    title="Holiday Schedule"
    backgroundColor="red"
    textColor="white"
    diagonal="dark"
    size="sm"
    align="start"
  >
  </PageHeader>
  <div class="container my-5">
    <h2 class="table-header">2024 Holidays</h2>
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Date</th>
          <th scope="col">Day</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">New Year’s Day</th>
          <td>January 1, 2024</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Martin Luther King, Jr. Day</th>
          <td>January 15, 2024</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Memorial Day</th>
          <td>May 27, 2024</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Juneteenth National Independence Day</th>
          <td>June 19, 2024</td>
          <td>Wednesday</td>
        </tr>
        <tr>
          <th scope="row">Independence Day</th>
          <td>July 4, 2024</td>
          <td>Thursday</td>
        </tr>
        <tr>
          <th scope="row">Labor Day</th>
          <td>September 2, 2024</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Thanksgiving</th>
          <td>November 28&ndash;29, 2024</td>
          <td>Thursday&ndash;Friday</td>
        </tr>
        <tr>
          <th scope="row">Christmas Holidays</th>
          <td>December 25&ndash;31, 2024</td>
          <td>Wednesday&ndash;Tuesday</td>
        </tr>
      </tbody>
    </table>
    <h2 class="table-header">2025 Holidays</h2>
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Date</th>
          <th scope="col">Day</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">New Year’s Day</th>
          <td>January 1, 2025</td>
          <td>Wednesday</td>
        </tr>
        <tr>
          <th scope="row">Martin Luther King, Jr. Day</th>
          <td>January 20, 2025</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Memorial Day</th>
          <td>May 26, 2025</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Juneteenth National Independence Day</th>
          <td>June 19, 2025</td>
          <td>Thursday</td>
        </tr>
        <tr>
          <th scope="row">Independence Day</th>
          <td>July 4, 2025</td>
          <td>Friday</td>
        </tr>
        <tr>
          <th scope="row">Labor Day</th>
          <td>September 1, 2025</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Thanksgiving</th>
          <td>November 27&ndash;28, 2025</td>
          <td>Thursday&ndash;Friday</td>
        </tr>
        <tr>
          <th scope="row">Christmas Holidays</th>
          <td>December 25&ndash;31, 2025</td>
          <td>Thursday&ndash;Wednesday</td>
        </tr>
      </tbody>
    </table>
    <h2 class="table-header">2026 Holidays</h2>
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Date</th>
          <th scope="col">Day</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">New Year’s Day</th>
          <td>January 1, 2026</td>
          <td>Thursday</td>
        </tr>
        <tr>
          <th scope="row">Martin Luther King, Jr. Day</th>
          <td>January 19, 2026</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Memorial Day</th>
          <td>May 25, 2026</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Juneteenth National Independence Day</th>
          <td>June 19, 2026</td>
          <td>Friday</td>
        </tr>
        <tr>
          <th scope="row">Independence Day</th>
          <td>July 3, 2026</td>
          <td>Friday</td>
        </tr>
        <tr>
          <th scope="row">Labor Day</th>
          <td>September 7, 2026</td>
          <td>Monday</td>
        </tr>
        <tr>
          <th scope="row">Thanksgiving</th>
          <td>November 26&ndash;27, 2026</td>
          <td>Thursday&ndash;Friday</td>
        </tr>
        <tr>
          <th scope="row">Christmas Holidays</th>
          <td>December 25&ndash;31, 2026</td>
          <td>Friday&ndash;Thursday</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: "Holiday Schedule",
  metaInfo: {
    title: "UGA Holiday Schedule",
    description: "",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  data() {
    return {};
  },
  components: {
    PageHeader,
    Breadcrumb,
  },
};
</script>

<style lang="scss" scoped>
.table {
  &-header {
    @include heading-base;
    font-size: $header-lg;
    color: $red;
    margin-bottom: 2rem;
    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }
}
</style>
