<template>
  <div class="breadcrumb bg-primary">
    <div v-if="!this.items" class="breadcrumb__container container">
      <div class="breadcrumb__link" v-for="(path, i) in urlArr" :key="path">
        <router-link v-if="i == 0" class="breadcrumb__text" to="/"
          >Home</router-link
        >
        <router-link
          v-else
          class="breadcrumb__text"
          :to="{ name: capitalize(path) }"
          >{{ path.split('-').join(' ') }}</router-link
        >
      </div>
    </div>
    <div v-else class="breadcrumb__container container">
      <div class="breadcrumb__link" v-for="(item, i) in this.items" :key="item">
        <router-link v-if="i == 0" class="breadcrumb__text" to="/"
          >Home</router-link
        >
        <router-link
          v-else-if="i == this.items.length - 1"
          class="breadcrumb__text"
          :to="this.$route"
          >{{ item }}</router-link
        >

        <router-link v-else class="breadcrumb__text" :to="`/${item}`">{{
          item
        }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  data() {
    return {
      urlArr: [],
    };
  },
  mounted() {
    this.urlArr = this.getUrl();
    console.log(this.items);
  },
  props: {
    items: {
      type: [],
      required: false,
    },
  },
  methods: {
    getUrl() {
      let path = this.$route.path.split('/');
      path = this.cleanPath(path);
      console.log(path);
      return path;
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    cleanPath(path) {
      path = path.filter((x) => {
        return x !== '';
      });
      path.unshift('');
      return path;
    },
  },
};
</script>
<style scoped lang="scss">
.breadcrumb {
  &__container {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  &__link {
    display: flex;
    align-items: center;
  }
  &__link:last-of-type > a {
    color: rgba(255, 255, 255, 0.5);
    &:hover,
    &:active {
      color: $white;
    }
  }
  &__link:not(:first-of-type)::before {
    color: $white;
    font-size: 0.6rem;
    content: '/';
    margin: 0 0.5rem;
  }

  &__text {
    @include heading-base;
    font-size: 0.75rem;
    font-weight: $font-weight-normal;
    letter-spacing: 0.1em;
    color: white;
  }
}
</style>
